<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button :to='{name: "emails.templates.template", params: {templateId: $route.params.templateId}}' variant="primary" size="sm">
          Back to Template
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card class="mb-5">
        <b-card-text>
          <FormTemplate v-if="form" :form="form">
            <div class="form-group">
                <label class="label-for-xl">Subject</label>
                <b-form-input
                    type="text" class="form-control form-control-xl"
                    placeholder="enter subject"
                    v-model="form.data.subject"
                    :state="form.states.subject"
                />
                <b-form-invalid-feedback v-if="form.errors">{{ form.errors.subject }}</b-form-invalid-feedback>
            </div>
            <div class="form-group">
                <label class="label-for-xl">Body</label>
                <editor
                  :init='{height: 500, plugins: "code image",paste_data_images: true}'
                  :api-key="tinymceKey"
                  v-model="form.data.body"
                  :state="form.states.body"
                  />
                <b-form-invalid-feedback v-if="form.errors">{{ form.errors.body }}</b-form-invalid-feedback>
            </div>
          </FormTemplate>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      FormTemplate: () => import('@/common/components/Form/Form'),
      editor: () => import('@tinymce/tinymce-vue')
    },
    data(){
      return {
        page: {
            title: null,
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Emails",
                url: this.$router.resolve({name: "emails"}).href
            },
            {
              title: "Email Templates",
              url: this.$router.resolve({name: "emails.templates"}).href
            }
        ],
        form: null,
        tinymceKey: process.env.VUE_APP_TINYMCE_KEY
      }
    },
    methods: {
      initForm(trans){
        this.form = new Form({subject: trans.subject || "", body: trans.body || ""},{
          onSubmit: (form) => {
            return this.$api.put(`emails/template/${this.$route.params.templateId}/translation/${this.$route.params.locale}`,{
              data: {
                subject: form.get('subject'),
                body: form.get('body')
              }
            });
          },
        });
      }
    },
    mounted(){
      this.$emit('set-sidebar',{active: "emails"});

      return this.$api.get(`emails/template/${this.$route.params.templateId}/translation/${this.$route.params.locale}`).then((res) => {
        let trans = res.data;
        this.page.title = `Edit "${this.$route.params.locale}" translation`;
        this.initForm(trans);
      }).catch(() => {
        this.initForm({subject: "", body: ""});
      })
    },
};
</script>
